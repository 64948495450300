import { useCallback, useEffect, useMemo, useState } from 'react';

import { atom, useAtom } from 'jotai';
import _ from 'lodash';

import { usePostEvent } from '@/apis';
import API_RESPONSE_CODE from '@/apis/axios/responseCode';
import { API_IS_MOCK, APP_IS_DEBUG } from '@/constants/development';
import useLiff from '@/hooks/useLiff';
import useLoading from '@/hooks/useLoading';
import getQueryString from '@/utils/getQueryString';
import getURLSearchParams from '@/utils/getURLSearchParams';
import { PropTypes } from '@/utils/propTypes';

import ConfirmAlert from '../ConfirmAlert';
import LoadingView from './LoadingView';

/** t為LIFF剛打開時要讀取的參數，所以寫入一次就好 */
const tAtom = atom(null);
const gkAtom = atom(null);
const gstAtom = atom(null);
/** Urchin Tracking Module參數，直接紀錄整包queryString */
export const utmAtom = atom(null);
/**
 * LIFF站台共用作業
 * 站台載入時，先執行此段作業
 * 套用LIFF取得LINE User ID並詢問導頁API
 *    LIFF站台: LM-LIFF-API-03 導頁事件 以上兩項可統合爲這隻
 *    [TODO] SIGNUP站台: 確認使用者身份
 * @param {object} props
 */
const SharedLayoutLiff = ({ children }) => {
  const lbKey = getQueryString('t');
  const gk = getQueryString('gk');
  const gst = getQueryString('gst');
  const [t, setT] = useAtom(tAtom);
  const [gameKey, setGameKey] = useAtom(gkAtom);
  const [gameShareToken, setGameShareToken] = useAtom(gstAtom);
  const [utm, setUTM] = useAtom(utmAtom);

  const [isAlertModalShow, setIsAlertModalShow] = useState(false);

  const devTestUid = useMemo(
    () => APP_IS_DEBUG && getQueryString('dev_test_uid'),
    []
  );

  useEffect(() => {
    if (API_IS_MOCK && APP_IS_DEBUG) {
      window.lmLog('Using mock API...');
    }
    // 僅跑一次，紀錄整包queryString
    // @ts-ignore
    setUTM(getURLSearchParams());
  }, [setUTM]);
  /** t為LIFF剛打開時要讀取的參數，所以寫入一次就好 */
  useEffect(() => {
    if (lbKey === null) {
      return;
    }
    if (t === lbKey) return;
    // @ts-ignore
    setT(lbKey);
  }, [lbKey, t, setT]);

  useEffect(() => {
    if (gk === null) {
      return;
    }
    if (gameKey === gk) return;
    // @ts-ignore
    setGameKey(gk);
  }, [gk, gameKey, setGameKey]);

  useEffect(() => {
    if (gst === null) {
      return;
    }
    if (gameShareToken === gst) return;
    // @ts-ignore
    setGameShareToken(gst);
  }, [gst, gameShareToken, setGameShareToken]);

  useEffect(() => {
    if (devTestUid) {
      window.lmLog('Using dev_test_uid:', devTestUid);
    }
  }, [devTestUid]);

  const { uid, isLoggedIn, quitLiff } = useLiff(devTestUid);
  /**
   * 2022-10-02 取消IP反查與系統公告，統合爲LM-LIFF-API-03
   * 2022-12-17 LIFF與SIGNUP站台分離作業
   *    LIFF站台: LM-LIFF-API-03 導頁事件 以上兩項可統合爲這隻
   */
  const {
    isLoading: isCheckingEvent,
    isError: isCheckingEventError,
    data: eventData,
  } = usePostEvent(lbKey, gameKey, gameShareToken, utm);

  const { isLoading, showLoading, hideLoading } = useLoading();

  /**
   * 判斷預載作業是否完成
   * 2022-10-02 移除IPNV檢查，此為額外收集資訊，不影響User操作，只要有送出去即可
   * 2022-12-17 依據站台區分條件
   * 判斷條件
   *   LM-LIFF站台:
   *     isLoading: 全域載入畫面
   *     isCheckingMaintenance: LM-LIFF-API-03 導頁事件
   *   LM-SIGNUP站台:
   *     isUserInfoLoading: LM-SIGNUP-API-01 判斷用戶身份
   *     isUserInfoGot: LM-SIGNUP-API-01 判斷用戶身份
   */
  const isProcessing = useMemo(
    () => isLoading || isCheckingEvent,
    [isCheckingEvent, isLoading]
  );

  const handleAlertClose = useCallback(() => {
    setIsAlertModalShow(false);
    quitLiff();
  }, [quitLiff]);

  /** 載入中關閉條件 */
  useEffect(() => {
    if (isLoggedIn && uid) hideLoading();
    else showLoading();
  }, [hideLoading, isLoggedIn, showLoading, uid]);

  /** EVENT API錯誤時Show警告訊息：網路不通/Server故障無法正確回應 */
  useEffect(() => {
    if (isCheckingEventError) {
      setIsAlertModalShow(true);
      hideLoading();
    }
  }, [isCheckingEventError, setIsAlertModalShow, hideLoading]);

  /**
   * 維護中則導向資格不符頁面
   * 取消使用Alert
   * setIsAlertModalShow(true);
   */
  useEffect(() => {
    if (isCheckingEvent) return;
    const code = _.get(eventData, 'code', null);
    const targetURL = _.get(eventData, 'data.target_url', null);
    window.lmLog('DEBUG:targetURL:', targetURL);
    if (code === API_RESPONSE_CODE.REDIRECT && targetURL) {
      if (typeof window !== 'undefined') {
        /** 不讓使用者上一頁 */
        window.location.replace(targetURL);
      }
      return;
    }
  }, [isCheckingEvent, eventData]);

  return (
    <>
      {isProcessing ? <LoadingView /> : children}
      <ConfirmAlert
        titleText="系統維護中"
        description="請稍候再試"
        buttonText="離開"
        show={isAlertModalShow}
        onConfirm={handleAlertClose}
      />
    </>
  );
};

SharedLayoutLiff.propTypes = {
  children: PropTypes.element,
};

export default SharedLayoutLiff;
