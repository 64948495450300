import { useCallback, useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';

import { atom, useAtom } from 'jotai';

import { useGetUserInfo, usePostEvent, USER_TYPE } from '@/apis';
import { API_IS_MOCK, APP_IS_DEBUG } from '@/constants/development';
import { REDIRECT_TO_URL } from '@/constants/urls';
import useLiff from '@/hooks/useLiff';
import useLoading from '@/hooks/useLoading';
import getQueryString from '@/utils/getQueryString';
import getURLSearchParams from '@/utils/getURLSearchParams';
import { PropTypes } from '@/utils/propTypes';

import ConfirmAlert from '../ConfirmAlert';
import LoadingView from './LoadingView';

export const entrySearchParamsAtom = atom({});
/**
 * SIGNUP站台共用作業
 * 站台載入時，先執行此段作業
 * 套用LIFF取得LINE User ID並詢問API確認使用者身份
 * @param {object} props
 * @returns
 */
const SharedLayout = ({ children }) => {
  const lbKey = getQueryString('t');
  const [utm, setUTM] = useAtom(entrySearchParamsAtom);
  const [isAlertModalShow, setIsAlertModalShow] = useState(false);

  const devTestUid = useMemo(
    () => APP_IS_DEBUG && getQueryString('dev_test_uid'),
    []
  );

  useEffect(() => {
    if (API_IS_MOCK && APP_IS_DEBUG) {
      window.lmLog('Using mock API...');
    }
  }, []);

  useEffect(() => {
    if (devTestUid) {
      window.lmLog('Using dev_test_uid:', devTestUid);
    }
  }, [devTestUid]);

  const { uid, isLoggedIn, quitLiff } = useLiff(devTestUid);
  /**
   * 2022-10-02 取消IP反查與系統公告，統合爲LM-LIFF-API-03
   * 2022-12-17 LIFF與SIGNUP站台分離作業
   *    LIFF站台: LM-LIFF-API-03 導頁事件 以上兩項可統合爲這隻
   */
  const {
    isServerMaintenance,
    isLoading: isCheckingEvent,
    data: eventData,
  } = usePostEvent(lbKey, null, null, utm);

  const { isLoading, showLoading, hideLoading } = useLoading();
  /**
   * 判斷用戶身份
   */
  const {
    userInfo,
    isSuccess: isUserInfoGot,
    isLoading: isUserInfoLoading,
  } = useGetUserInfo();

  /**
   * 判斷預載作業是否結束
   * isProcessing: true=處理中, false=載入完成
   * 2022-12-17
   *     isCheckingEvent: 檢查是否維護中
   *     isUserInfoLoading: API調用中
   *     isUserInfoGot: 已取得身份
   */
  const isProcessing = useMemo(
    () => isLoading || isCheckingEvent || isUserInfoLoading || !isUserInfoGot,
    [isLoading, isCheckingEvent, isUserInfoGot, isUserInfoLoading]
  );

  const handleAlertClose = useCallback(() => {
    setIsAlertModalShow(false);
    quitLiff();
  }, [quitLiff]);

  /** 載入中關閉條件 */
  useEffect(() => {
    if (isLoggedIn && uid) hideLoading();
    else showLoading();
  }, [hideLoading, isLoggedIn, showLoading, uid]);

  /**
   * 維護中則導向資格不符頁面
   */
  useEffect(() => {
    if (isServerMaintenance) {
      navigate('/message', {
        replace: true,
        state: eventData,
      });
      hideLoading();
    }
  }, [isServerMaintenance, eventData, hideLoading]);

  /** 判斷目標頁面 */
  useEffect(() => {
    /**
     * MVP 還沒分離先hardcode
     * LM-LIFF 負責檢驗身份跟轉導，不用自動進入註冊頁面
     * LM-Signup 站台才需要進入綁定/解除綁定頁面
     */

    /** 這段為綁定/解除綁定頁的檢核 */
    if (!userInfo.type) return;

    hideLoading();

    const lbKey = getQueryString('t');

    /** 沒有lbKey提早返回 */
    if (!lbKey) return;
    /** 切換頁面前紀錄參數 */
    const entrySearchParams = getURLSearchParams();
    setUTM(entrySearchParams);

    /** 註冊綁定頁 */
    if (lbKey === 'LM_SIGNUP_LINK') {
      if (userInfo.type === USER_TYPE.UN_SIGNED) {
        navigate('/sign-up', { replace: true });
      } else if (userInfo.type === USER_TYPE.SIGNED) {
        navigate('/signed', { replace: true });
      } else {
        navigate('/unqualified', {
          replace: true,
          state: {
            title: '您已綁定囉',
            target_url: REDIRECT_TO_URL.LINE_OA_FOLLOW,
          },
        });
      }
    }

    /** 解除綁定頁 */
    if (lbKey === 'LM_SIGNUP_UNLINK') {
      if (
        userInfo.type === USER_TYPE.SIGNED ||
        userInfo.type === USER_TYPE.HAS_BIND
      ) {
        navigate('/unbind', { replace: true });
      } else {
        navigate('/unqualified', {
          replace: true,
          state: {
            title: '您尚未綁定',
            target_url: REDIRECT_TO_URL.LINE_OA_FOLLOW,
          },
        });
      }
    }
  }, [userInfo, hideLoading, setUTM]);

  return (
    <>
      {isProcessing ? <LoadingView /> : children}
      <ConfirmAlert
        titleText="系統維護中"
        description="請稍候再試"
        buttonText="離開"
        show={isAlertModalShow}
        onConfirm={handleAlertClose}
      />
    </>
  );
};

SharedLayout.propTypes = {
  children: PropTypes.element,
};

export default SharedLayout;
