const API_RESPONSE_CODE = {
  OK: 200,
  REDIRECT: 100101,
  CONFIRM_TO_REDIRECT: 100102,
  INVALID_REDIRECT: 100103,
  NEED_LOGIN: 100403,
  MESSAGE_TYPE_ACTIVITY_END: 100410,
  MESSAGE_TYPE_ERROR: 100500,
  MESSAGE_TYPE_SYSTEM_BUSY: 1005031,
  MESSAGE_TYPE_SYSTEM_MAINTAIN: 1005032,
};

export default API_RESPONSE_CODE;
