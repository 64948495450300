import { AxiosResponseClass, DEFAULT_API_TIMEOUT } from '../axios';
import { mockApiResponse } from './data';

const MOCK_DELAY = 500;

const getMockData = (
  /** @type {string} */ url,
  /** @type {import('axios').AxiosRequestConfig} */ config
) => {
  const data = mockApiResponse[url];
  return new AxiosResponseClass({
    data,
    status: 200,
    statusText: 'OK',
    headers: {
      'access-control-allow-origin': '*',
      connection: 'close',
      'content-length': JSON.stringify(data)?.length.toString(),
      'content-type': 'application/json; charset=utf-8',
      date: new Date().toLocaleDateString(),
      server: 'Microsoft-IIS/8.5',
      vary: 'Accept-Encoding',
      'x-powered-by': 'ASP.NET',
    },
    config: {
      transitional: {
        silentJSONParsing: true,
        forcedJSONParsing: true,
        clarifyTimeoutError: false,
      },
      transformRequest: [null],
      transformResponse: [null],
      timeout: DEFAULT_API_TIMEOUT,
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN',
      maxContentLength: -1,
      maxBodyLength: -1,
      env: { FormData: null },
      headers: { Accept: 'application/json, text/plain, */*' },
      baseURL: config?.baseURL,
      params: config?.params,
      url: url,
      method: config?.method,
    },
    request: {},
  });
};

export const useMockApi = () => {
  /** @type {(url: string, config?: import("axios").AxiosRequestConfig) => Promise<import("axios").AxiosResponse>} */
  const apiMock = (url, config) =>
    new Promise((resolve, reject) => {
      try {
        setTimeout(() => {
          resolve(getMockData(url, config));
        }, MOCK_DELAY);
      } catch (error) {
        reject(error);
      }
    });

  return {
    /** @type {(url: string, config?: import("axios").AxiosRequestConfig) => Promise<import("axios").AxiosResponse>} */
    apiGet: (url, config) =>
      apiMock(url, Object.assign({ method: 'get' }, config)),

    /** @type {(url: string, config?: import("axios").AxiosRequestConfig) => Promise<import("axios").AxiosResponse>} */
    apiPost: (url, config) =>
      apiMock(url, Object.assign({ method: 'post' }, config)),
  };
};
