import { useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';

import LIFFInspectorPlugin from '@line/liff-inspector';
import { LiffMockPlugin } from '@line/liff-mock';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';
import qs from 'query-string';

import { PT_MODE_IS_ENABLED } from '@/constants/development';

import {
  APP_IS_DEBUG,
  BASE_URL,
  BYPASS_CHECK_LIFF_CLIENT,
  LIFF_ID,
  LIFF_IS_ENABLED,
  LIFF_REDIRECT_URL_FOR_CHECK,
  LIFF_TEST_UID,
  REDIRECT_TO_URL,
} from '../constants';

/** @type import('@line/liff/dist/lib').Liff */
const typeLiff = undefined;

// should be false in production
const bypassCheckLiffClient = BYPASS_CHECK_LIFF_CLIENT;

const hasLiffRequirementAtom = atom(false);
const liffAtom = atom(typeLiff);
const isUserInvalidAtom = atom(false);
const isLiffInitializedAtom = atom(false);
const isLiffInitializingAtom = atom(false);

export const uidAtom = atom('');
export const accessTokenAtom = atom('');
export const idTokenAtom = atom('');
export const isLoggedInAtom = atom(false);

/** 2022-11-15 for PTMode */
/** 2022-11-28 for PT badboy */
export const isInPTModeAtom = atom(false);
export const isPTModeInitializingAtom = atom(false);

const useLiffEnabled = (/** @type {string?} */ devTestUid) => {
  const [liff, setLiff] = useAtom(liffAtom);
  const [uid, setUid] = useAtom(uidAtom);
  const [idToken, setIDToken] = useAtom(idTokenAtom);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [hasLiffRequirement, setHasLiffRequirement] = useAtom(
    hasLiffRequirementAtom
  );
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const [isUserInvalid, setIsUserInvalid] = useAtom(isUserInvalidAtom);
  const [isLiffInitialized, setIsLiffInitialized] = useAtom(
    isLiffInitializedAtom
  );
  const [isLiffInitializing, setIsLiffInitializing] = useAtom(
    isLiffInitializingAtom
  );
  const [isPTModeInitializing, setIsPTModeInitializing] = useAtom(
    isPTModeInitializingAtom
  );

  const [isInPTMode, setIsInPTMode] = useAtom(isInPTModeAtom);
  /**
   * Check PTMode requirements
   * 2022-11-15 for 壓力測試/滲透測試略過LIFF檢查
   */
  useEffect(() => {
    window.lmWarn(`PT_MODE_IS_ENABLED:${PT_MODE_IS_ENABLED}`);
    if (isPTModeInitializing) return;
    /** 沒開啟的話直接關閉 */
    if (!PT_MODE_IS_ENABLED) {
      setIsPTModeInitializing(true);
      setIsInPTMode(false);
      return;
    }
    window.lmWarn('Detect PT Mode：上線需移除');
    const urlState = qs.parse(location.search);
    const isPTMode = _.get(urlState, 'ptmode', 'N') === 'Y';
    const isInCypress =
      typeof window !== 'undefined' && typeof window.Cypress !== 'undefined';
    window.lmWarn(`判斷是否啟用PTMode isPTMode:${isPTMode}`);
    window.lmWarn(`判斷是否在Cypress裡 isInCypress:${isInCypress}`);
    if (isPTMode || isInCypress) {
      setIsPTModeInitializing(true);
      setIsInPTMode(true);
    }
  }, [isPTModeInitializing, setIsPTModeInitializing, setIsInPTMode]);

  // Check LIFF requirements
  useEffect(() => {
    if (isLiffInitialized) return;
    let meetRequirements = true;
    if (typeof window !== 'undefined' && LIFF_IS_ENABLED) {
      window.lmLog('LIFF is Enabled, start to check the requirements');
      if (!LIFF_ID) {
        window.lmError(
          'Miss environment variable: `GATSBY_LIFF_ID`, please setup the environment variables to enable LIFF'
        );
        meetRequirements = false;
      }
      if (window.location.protocol !== 'https:') {
        window.lmError(
          'HTTPS is required for LIFF, please restart the app with HTTPS protocal'
        );
        meetRequirements = false;
      }
      if (!LIFF_REDIRECT_URL_FOR_CHECK) {
        window.lmWarn(
          '`GATSBY_BASE_URL` is not set, will use default value to redirect the user after LIFF logged in. (default to :https://0.0.0.0:3000)'
        );
      }
      if (meetRequirements) {
        window.lmLog('Has requirements for LIFF, start initializing...');
      }
      setHasLiffRequirement(meetRequirements);
    }
  }, [isLiffInitialized, setHasLiffRequirement]);

  // import LIFF package
  useEffect(() => {
    if (liff == null) {
      /** @type import('@line/liff/dist/lib').Liff */
      // @ts-ignore
      const liffLib = require('@line/liff/dist/lib');
      setLiff(liffLib);
    }
  }, [liff, setLiff]);

  // Initialize LIFF
  const init = useCallback(() => {
    if (liff == null) return;
    if (!isLiffInitializing) {
      if (isInPTMode) {
        window.lmWarn('isInPTMode', isInPTMode);
        window.lmWarn('Using Liff Mock...');
        liff.use(new LiffMockPlugin());
        const urlState = qs.parse(location.search);
        const userId = _.get(urlState, 'uid', 'U001');
        window.lmWarn('Use PTMode User ID', userId);
        // @ts-ignore
        liff.$mock.set((p) => ({
          ...p,
          getProfile: {
            displayName: '壓測使用者：' + userId,
            userId: userId,
          },
          isLoggedIn: true,
        }));
      }

      liff.init({
        liffId: LIFF_ID,
        // @ts-ignore
        // mock: typeof window !== 'undefined' && window.Cypress,
        mock: isInPTMode,
      });
    }
    setIsLiffInitializing(true);
  }, [isLiffInitializing, liff, setIsLiffInitializing, isInPTMode]);

  // Determines whether the LIFF app is running in a LIFF browser.
  const checkIsInLiffClient = useCallback(
    () => isLiffInitialized && liff.isInClient(),
    [isLiffInitialized, liff]
  );
  /** 取得User Access Token */
  const getAccessToken = useCallback(() => {
    if (accessToken || liff == null) return;
    if (!isLiffInitialized || !isLoggedIn) return;
    const accessTokenFromLIFF = liff.getAccessToken();
    setAccessToken(accessTokenFromLIFF);
  }, [isLiffInitialized, isLoggedIn, liff, setAccessToken, accessToken]);

  /** 滲透前端取得ID Token，讓後端來取得UID */
  const getIDToken = useCallback(() => {
    if (idToken || liff == null) return;
    if (!isLiffInitialized || !isLoggedIn) return;
    const idTokenFromLIFF = liff.getIDToken();
    setIDToken(idTokenFromLIFF);
  }, [isLiffInitialized, isLoggedIn, liff, setIDToken, idToken]);

  // Get Line user ID
  const getUid = useCallback(() => {
    if (uid || liff == null) return;
    if (!isLiffInitialized || !isLoggedIn) return;

    liff
      .getProfile()
      .then((userProfile) => {
        const { userId } = userProfile;
        if (userId) {
          if (process.env.NODE_ENV === 'development') {
            if (devTestUid) {
              window.lmLog('Using test UID...');
              setUid(devTestUid);
              return;
            } else if (LIFF_TEST_UID) {
              window.lmLog('Using test UID...');
              setUid(LIFF_TEST_UID);
              return;
            }
          }
          setUid(userId);
        }
      })
      .catch((error) => {
        window.lmError('LIFF error:', error);
      });
  }, [devTestUid, isLiffInitialized, isLoggedIn, liff, setUid, uid]);

  const quitLiff = useCallback(() => {
    liff?.openWindow({ url: REDIRECT_TO_URL.LINE_OA_FOLLOW });
    liff?.closeWindow();
  }, [liff]);

  useEffect(() => {
    if (!hasLiffRequirement || !liff || !LIFFInspectorPlugin) return;
    if (!isLiffInitialized) {
      if (APP_IS_DEBUG) {
        window.lmLog('Using LIFF Inspector...');
        // liff.use(new LIFFInspectorPlugin({ origin: LIFF_INSPECTOR_ORIGIN }));
      }
      init();
    }
  }, [hasLiffRequirement, init, isLiffInitialized, liff]);

  /** 判斷LIFF是否載入完成，準備初始化與使用者登入 */
  useEffect(() => {
    if (isUserInvalid) return;
    /** 還沒初始化不用往下走 */
    if (!isLiffInitialized) return;

    const isLiffLoggedIn = liff.isLoggedIn();
    /** 未登入作業：執行liff.login */
    if (!isLiffLoggedIn) {
      window.lmLog('login LIFF manually...', window.location.href);
      let redirectUri = BASE_URL;
      if (
        typeof window !== 'undefined' &&
        typeof window.location !== 'undefined' &&
        typeof window.location.href !== 'undefined'
      ) {
        redirectUri = window.location.href;
      }
      /** 登入後回到目前網址 */
      liff.login({
        redirectUri: redirectUri,
      });
      return;
    }

    /** 以下為已登入作業 */
    if (!bypassCheckLiffClient && !checkIsInLiffClient()) {
      if (APP_IS_DEBUG)
        window.lmError(new Error('LIFF ERROR: NOT IN A LIFF CLIENT'));
      console.log(`setIsUserInvalid: ${setIsUserInvalid}`);
      setIsUserInvalid(true);

      /** 非LIFF Client轉導到官方網站 */
      navigate('/unqualified', {
        replace: true,
        state: {
          title: '本頁面僅可在LINE中開啟',
          target_url: REDIRECT_TO_URL.LINE_OA_FOLLOW,
        },
      });
    } else {
      getAccessToken();
      getIDToken();
      getUid();
    }

    setIsLoggedIn(isLiffLoggedIn);
  }, [
    checkIsInLiffClient,
    getUid,
    getIDToken,
    isLiffInitialized,
    isUserInvalid,
    liff,
    setIsLoggedIn,
    setIsUserInvalid,
    getAccessToken,
  ]);

  useEffect(() => {
    if (liff == null) return;
    if (!isLiffInitialized) {
      /** 在PTMode裡，略過常規登入 */
      if (isInPTMode) {
        liff.login();
        setIsLiffInitialized(true);
        // 提早返回，liffMock沒有ready可用
        return;
      }

      liff.ready
        .then(() => {
          window.lmLog('LIFF is ready');
          setIsLiffInitialized(true);
        })
        .catch((error) => {
          window.lmError('LIFF error:', error);
        });
    }
  }, [isLiffInitialized, liff, setIsLiffInitialized, isInPTMode]);

  useEffect(() => {
    if (isUserInvalid) {
      window.location.replace(REDIRECT_TO_URL.LINE_OA_FOLLOW);
    }
  }, [isUserInvalid, liff]);

  return {
    uid,
    isLoggedIn,
    quitLiff,
  };
};

const useLiffDisabled = () => ({
  uid: '',
  isLoggedIn: false,
  quitLiff: () => undefined,
});

const useLiff = LIFF_IS_ENABLED ? useLiffEnabled : useLiffDisabled;

export default useLiff;
