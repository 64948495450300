const API_URL = {
  // LM-SIGNUP-API-01 判斷用戶身份
  USER_INFO: '/user/info',

  // LM-SIGNUP-API-02 發送OTP
  OTP_SEND: '/otp/send',

  // LM-SIGNUP-API-03 綁定門號
  MOBILE_LINK: '/mobile/link',

  // LM-SIGNUP-API-04 解除門號
  MOBILE_UNLINK: '/mobile/unlink',

  // LM-SINGUP-API-05 解除舊用戶門號
  MOBILE_UNLINK_OLD_USER: '/mobile/unlink/old-user',

  // LM-LIFF-API-01 IP反查
  IPNV: '/ipnv',

  // LM-LIFF-API-02 系統公告:業務停用檢查
  MAINTENANCE: '/maintenance',

  // LM-LIFF-API-03 導頁事件
  EVENT: '/event',
};

// use module.exports for the Gatsby dev proxy server
module.exports = { API_URL };
