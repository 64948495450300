/**
 * 警示框
 */
import { useCallback } from 'react';

import { Button, Modal } from 'antd';
import styled from 'styled-components';

import { PropTypes } from '@/utils/propTypes';

const DescriptionBlock = styled.p`
  margin: 0;
  color: #949494;
  font-size: 14px;
  line-height: 20px;
  ${({ $isDebugError }) =>
    $isDebugError &&
    `
    white-space: pre-wrap;
    width: 100%;
  `}
`;

const Alert = styled(Modal)`
  border-radius: 12px;
  .ant-modal-content {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 0 24px;
  }
  .ant-modal-header,
  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
  .ant-modal-header {
    border-radius: 12px;
    border-bottom: none;
    padding: 0;
    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
  .ant-modal-body {
    margin-top: 12px;
    padding: 0;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 0;
  }
`;

const ConfirmButton = styled(Button)`
  margin-top: 24px;
  margin-top: 24px;
  font-size: 16px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  line-height: 22px;
  font-weight: 600;
  border-radius: 5px;
`;

const ConfirmAlert = ({
  titleText,
  description,
  buttonText,
  show,
  onConfirm,
  isDebugError,
}) => {
  const handleModalConfirm = useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  return (
    <Alert
      title={titleText}
      centered={true}
      footer={[
        <ConfirmButton key="ok" type="primary" onClick={handleModalConfirm}>
          {buttonText}
        </ConfirmButton>,
      ]}
      closable={false}
      open={show}
    >
      <DescriptionBlock $isDebugError={isDebugError}>
        {description}
      </DescriptionBlock>
    </Alert>
  );
};

ConfirmAlert.propTypes = {
  titleText: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  isDebugError: PropTypes.bool,
};

export default ConfirmAlert;
