import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import _ from 'lodash';

import { accessTokenAtom, idTokenAtom, isLoggedInAtom } from '@/hooks/useLiff';

import { handleApiError, handleApiResponse, useLiffApi } from '../axios';
import { API_URL } from '../axios/urls';

/**
 * LM-LIFF-API-03 導頁事件v2.0
 * 改送 t & access token
 * @param {*} t
 * @returns
 */
export const usePostEvent = (
  /** @type {string} */ t,
  /** @type {string} */ gk = null,
  /** @type {string} */ gst = null,
  /** @type {object} */ utm = null
) => {
  const accessToken = useAtomValue(accessTokenAtom);
  const idToken = useAtomValue(idTokenAtom);
  const isLoggedIn = useAtomValue(isLoggedInAtom);

  const { apiPost } = useLiffApi();
  /**
   * @typedef {import("../axios/types").ApiResponseData} ApiResponseData
   */
  /** @type {() => Promise<object| ApiResponseData>} */
  const postEvent = () =>
    apiPost(API_URL.EVENT, {
      data: _.omitBy(
        {
          t,
          gk,
          gst,
          current_url: _.get(window, 'location.href'),
          line_access_token: accessToken,
          line_id_token: idToken,
          utm,
        },
        _.isNull
      ),
    })
      .then((response) => handleApiResponse(response))
      .catch((error) => {
        /** 加入錯誤處理停止前端loading */
        console.error(`${API_URL.EVENT} 錯誤${error.constructor.name}`, error);

        handleApiError(error);
      });

  const qReturns = useQuery([API_URL.EVENT], postEvent, {
    /** 判斷沒t也要送 */
    enabled: isLoggedIn && !!accessToken,
    // enabled: isLoggedIn && !!uid && !!t,
  });
  const { data, isSuccess, isLoading, isError } = qReturns;

  return {
    data,
    isSuccess,
    isLoading,
    isError,
    /** 這段要補 */
    isServerMaintenance: false,
  };
};
