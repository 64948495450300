import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue } from 'jotai';

import { accessTokenAtom, isLoggedInAtom } from '@/hooks/useLiff';
import getQueryString from '@/utils/getQueryString';

import { handleApiError, handleApiResponse, useSignUpApi } from '../axios';
import { API_URL } from '../axios/urls';

export const USER_TYPE = {
  NONE: 0,
  UN_SIGNED: 1,
  SIGNED: 2,
  HAS_BIND: 3,
};

export const userInfoAtom = atom({ type: USER_TYPE.NONE, mobile: '' });

// LM-SIGNUP-API-01 判斷用戶身份
export const useGetUserInfo = () => {
  const t = getQueryString('t');
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const accessToken = useAtomValue(accessTokenAtom);
  const isLoggedIn = useAtomValue(isLoggedInAtom);

  const { apiGet } = useSignUpApi();

  /** @type {() => Promise<{type: number, mobile: string}>} */
  const getUserInfo = () =>
    apiGet(API_URL.USER_INFO, {
      params: { line_access_token: accessToken, t },
    })
      .then((response) => handleApiResponse(response)?.data)
      .catch(handleApiError);

  const { data, isSuccess, isLoading } = useQuery(
    [API_URL.USER_INFO],
    getUserInfo,
    {
      enabled: isLoggedIn && !!accessToken && !userInfo.type,
    }
  );

  useEffect(() => {
    if (isLoading || !isSuccess || !data) return;
    setUserInfo(data);
  }, [data, isSuccess, isLoading, setUserInfo]);

  return {
    userInfo,
    isSuccess,
  };
};
