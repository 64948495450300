import { navigate } from 'gatsby';

import { AxiosError } from 'axios';
import HTTP_STATUS from 'http-status-codes';
import _ from 'lodash';

import { API_IS_MOCK } from '@/constants/development';

import API_RESPONSE_CODE from './responseCode';
import { ApiError, ApiResponseData } from './types';

export const handleApiResponse = (
  /** @type import('axios').AxiosResponse<ApiResponseData> */ response
) => {
  if (response && response.status === HTTP_STATUS.OK && response.data) {
    if (response.data.code === API_RESPONSE_CODE.OK) {
      if (API_IS_MOCK) {
        window.lmLog(
          `Mock API - [${response.config.method.toUpperCase()} "${
            response.config.url
          }"]`,
          response.data.data
        );
      }
      return new ApiResponseData({
        code: response.data.code,
        data: response.data.data,
        reason: response.data.reason,
      });
    }

    if (response.data.code === API_RESPONSE_CODE.REDIRECT) {
      /**
       * Review 2023-11-25
       * LIFF跟SIGNUP都共用API
       * 把API_RESPONSE_CODE.REDIRECT
       * 拉到SharedLayoutLiff去轉導
       * if (typeof window !== 'undefined' && 'target_url' in response.data.data) {
       *   // 不讓使用者上一頁
       *   window.location.replace(response.data.data.target_url);
       * }
       */
      return new ApiResponseData({
        code: response.data.code,
        data: response.data.data,
        reason: response.data.reason,
      });
    }
    /**
     * 需要訊息畫面呈現的錯誤，直接從這邊處理
     */
    if (
      _.includes(
        [
          API_RESPONSE_CODE.CONFIRM_TO_REDIRECT,
          API_RESPONSE_CODE.INVALID_REDIRECT,
          API_RESPONSE_CODE.NEED_LOGIN,
          API_RESPONSE_CODE.MESSAGE_TYPE_ACTIVITY_END,
          API_RESPONSE_CODE.MESSAGE_TYPE_ERROR,
          API_RESPONSE_CODE.MESSAGE_TYPE_SYSTEM_BUSY,
          API_RESPONSE_CODE.MESSAGE_TYPE_SYSTEM_MAINTAIN,
        ],
        response.data.code
      )
    ) {
      navigate('/message', {
        replace: true,
        state: {
          code: response.data.code,
          ..._.get(response, 'data.data', {}),
        },
      });

      return new ApiResponseData({
        code: response.data.code,
        data: response.data.data,
        reason: response.data.reason,
      });
    }
  }

  throw new ApiError({
    baseUrl: response?.config?.baseURL,
    method: response?.config?.method,
    url: response?.config?.url,
    code: response?.data?.code,
    data: response?.data?.data,
    status: response?.status,
    message: response?.data?.reason,
  });
};

export const handleApiError = (/** @type unknown */ error) => {
  if (error instanceof AxiosError) {
    throw new ApiError({
      baseUrl: error.config?.baseURL,
      method: error.config?.method,
      url: error.config?.url,
      code: undefined,
      data: undefined,
      status: error.response?.status,
      message: error.response?.statusText,
    });
  } else if (error instanceof ApiError) {
    throw error;
  }

  const unknownError = new Error(`Unknown Error: ${error}`);

  window.lmError(unknownError);

  throw unknownError;
};
