import { useMutation } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import _ from 'lodash';

import { useGetUserInfo } from '@/apis/hooks';
import { accessTokenAtom } from '@/hooks/useLiff';

import { handleApiError, handleApiResponse, useSignUpApi } from '../axios';
import { API_URL } from '../axios/urls';

// LM-SIGNUP-API-04 解除門號
export const usePostMobileUnLink = () => {
  const accessToken = useAtomValue(accessTokenAtom);
  const { userInfo } = useGetUserInfo();
  const { apiPost } = useSignUpApi();

  /** @type {() => Promise<{title: string, message: string}>} */
  const postMobileUnLink = () =>
    apiPost(API_URL.MOBILE_UNLINK, {
      data: { line_access_token: accessToken, token: _.get(userInfo, 'token') },
    })
      .then((response) => handleApiResponse(response)?.data)
      .catch(handleApiError);

  const { mutate, isSuccess, isLoading } = useMutation(
    [API_URL.MOBILE_UNLINK],
    postMobileUnLink
  );

  return {
    mutate,
    isSuccess,
    isLoading,
  };
};
