import svgLoading from '@/assets/icons/svg-loading.svg';
import SVG from '@/patterns/SVG';
import { styled } from '@/utils/styledComponents';

const LoadingContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const rotateDegree = 360 * 4;

const LoadingIcon = styled(SVG)`
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(${rotateDegree}deg);
    }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(${rotateDegree}deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(${rotateDegree}deg);
    }
  }
  animation: spin 1.5s infinite ease-in-out;
`;

const LoadingText = styled.div`
  margin-top: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #949494;
`;

// const LoadingIcon =;

const LoadingView = () => {
  return (
    <LoadingContainer>
      <LoadingIcon src={svgLoading} />
      <LoadingText>載入中</LoadingText>
    </LoadingContainer>
  );
};

export default LoadingView;
