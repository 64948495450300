import InlineSVG from 'react-inlinesvg/esm';

import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

const StyledInlineSVG = styled(InlineSVG)`
  display: block;
`;

const SVG = ({ src, ...props }) =>
  src ? <StyledInlineSVG src={src} {...props} /> : null;

SVG.propTypes = {
  src: PropTypes.string.isRequired,
};

export default SVG;
