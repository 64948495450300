const URL = {
  SIGNUP_PAGE: '/sign-up',
  UNBIND: '/unbind',
  LINE_OA_FOLLOW:
    process.env.GATSBY_LINE_OA_FOLLOW || 'https://line.me/R/ti/p/@522knfdz',
};

export const REDIRECT_TO_URL = {
  LM_SIGNUP_LINK: URL.SIGNUP_PAGE,
  LM_SIGNUP_UNLINK: URL.UNBIND,
  LINE_OA_FOLLOW: URL.LINE_OA_FOLLOW,
};
