import axios from 'axios';

import { API_IS_MOCK } from '@/constants/development';
import { LIFF_API_URL, SIGN_UP_API_URL } from '@/constants/endpoints';

import { useMockApi } from '../mock';

/** DEFAULT NO TIMEOUT */
export const DEFAULT_API_TIMEOUT = 0;

const defaultApiConfig = {
  timeout: DEFAULT_API_TIMEOUT,
  headers: {},
};

const getAxiosConfig = (
  /** @type {string} */ baseURL,
  /** @type {string} */ method
) => Object.assign(defaultApiConfig, { baseURL, method });

const getLiffApiConfig = (/** @type {string} */ method) =>
  getAxiosConfig(LIFF_API_URL, method);

const getSignUpApiConfig = (/** @type {string} */ method) =>
  getAxiosConfig(SIGN_UP_API_URL, method);

const useLiffApiHook = () => {
  const apiGet = axios.create(getLiffApiConfig('GET'));
  const apiPost = axios.create(getLiffApiConfig('POST'));

  return {
    apiGet,
    apiPost,
  };
};

const useSignUpApiHook = () => {
  const apiGet = axios.create(getSignUpApiConfig('GET'));
  const apiPost = axios.create(getSignUpApiConfig('POST'));

  return {
    apiGet,
    apiPost,
  };
};

export const useLiffApi = API_IS_MOCK ? useMockApi : useLiffApiHook;
export const useSignUpApi = API_IS_MOCK ? useMockApi : useSignUpApiHook;
