import { useMutation } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { accessTokenAtom } from '@/hooks/useLiff';

import { encrypt } from '../../utils/crypt';
import { handleApiError, handleApiResponse, useSignUpApi } from '../axios';
import { API_URL } from '../axios/urls';

// LM-SIGNUP-API-02 發送OTP
export const usePostOtpSend = () => {
  const accessToken = useAtomValue(accessTokenAtom);

  const { apiPost } = useSignUpApi();

  /** @type {(mobile: string) => Promise<{expire_date: string, otp: string}>} */
  const postOtpSend = (/** @type {string} */ mobile) =>
    apiPost(API_URL.OTP_SEND, {
      data: { line_access_token: accessToken, mobile: encrypt(mobile) },
    })
      .then((response) => handleApiResponse(response)?.data)
      .catch(handleApiError);

  const { mutate, isSuccess, isLoading } = useMutation(
    [API_URL.OTP_SEND],
    postOtpSend
  );

  return {
    mutate,
    isSuccess,
    isLoading,
  };
};
