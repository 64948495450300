export const SITE = process.env.GATSBY_SITE || 'LIFF';

let liffID = null;
if (SITE === 'LIFF') liffID = process.env.GATSBY_LIFF_ID_LIFF;
if (SITE === 'SIGNUP') liffID = process.env.GATSBY_LIFF_ID_SIGNUP;
export const LIFF_ID = liffID;

export const LIFF_TEST_UID = process.env.GATSBY_LIFF_TEST_UID || '';

export const LIFF_IS_ENABLED =
  (process.env.GATSBY_LIFF_IS_ENABLED || 'false') === 'true';

export const BYPASS_CHECK_LIFF_CLIENT =
  (process.env.GATSBY_BYPASS_CHECK_LIFF_CLIENT || 'false') === 'true';

export const LIFF_REDIRECT_URL_FOR_CHECK = process.env.GATSBY_BASE_URL || '';
export const LIFF_INSPECTOR_ORIGIN =
  process.env.GATSBY_LIFF_INSPECTOR_ORIGIN || '';
