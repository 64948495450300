import { API_URL } from '../axios/urls';

const USER_TYPE = {
  NONE: 0,
  UN_SIGNED: 1,
  SIGNED: 2,
  HAS_BIND: 3,
};

export const mockApiResponse = {
  [API_URL.USER_INFO]: {
    code: 200,
    reason: 'SUCCESS',
    data: {
      type: USER_TYPE.SIGNED,
      mobile: '0977334567',
    },
  },
  [API_URL.OTP_SEND]: {
    code: 200,
    reason: 'SUCCESS',
    data: {
      expire_date: '2022-09-22 13:23:59',
      otp: '334567',
    },
  },
  [API_URL.MOBILE_LINK]: {
    code: 200,
    reason: 'SUCCESS',
    data: {
      title: '綁定成功',
      message: '恭喜你！你已完成綁定囉！',
    },
  },
  [API_URL.MOBILE_UNLINK]: {
    code: 200,
    reason: 'SUCCESS',
    data: {
      title: '解除門號綁定成功！',
      message: '取消綁定會錯過更多好康優惠喔！',
    },
  },
  [API_URL.IPNV]: {
    code: 200,
    reason: 'SUCCESS',
    data: {
      ip: '127.0.0.1',
    },
  },
  [API_URL.MAINTENANCE]: {
    code: 200,
    reason: 'SUCCESS',
    data: {},
  },
  [API_URL.REDIRECT]: {
    code: 200,
    reason: '導轉網頁',
    data: {
      target: 'https://google.com',
    },
  },
};
