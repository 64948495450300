import React, { useCallback, useMemo, useState } from 'react';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ConfigProvider } from 'antd';

// import { element } from 'prop-types';
import { ApiError } from '@/apis/axios';
import ConfirmAlert from '@/components/ConfirmAlert';
// import SharedLayout from '@/components/SharedLayout';
import SharedLayoutLiff from '@/components/SharedLayout/SharedLayoutLiff';
import SharedLayoutSignup from '@/components/SharedLayout/SharedLayoutSignup';
import { PropTypes } from '@/utils/propTypes';

import { APP_IS_DEBUG, SITE } from './src/constants';

import '@/styles/reset.scss';
import '@/styles/main.scss';
import 'antd/dist/antd.variable.min.css';

if (window != null && window.console) {
  window.lmLog = (
    /** @type {any} */ message,
    /** @type {any} */ ...optionalParams
  ) => window.console.log(`[LOG] ${message}`, ...optionalParams);
  window.lmError = (
    /** @type {any} */ message,
    /** @type {any} */ ...optionalParams
  ) => window.console.error(`[ERROR] ${message}`, ...optionalParams);
  window.lmWarn = (
    /** @type {any} */ message,
    /** @type {any} */ ...optionalParams
  ) => window.console.warn(`[WARNING] ${message}`, ...optionalParams);
}

if (!APP_IS_DEBUG) {
  if (window != null && window.console) {
    window.console.log = () => undefined;
    window.console.info = () => undefined;
    window.console.warn = () => undefined;
    window.console.error = () => undefined;
    window.lmLog = () => undefined;
    window.lmError = () => undefined;
  }
}

// Variables: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
ConfigProvider.config({
  theme: {
    primaryColor: '#06C755',
    errorColor: '#FF334B',
  },
});
const SCROLL_X_WIDTH = 375;
const MOBILE_MIN_WIDTH = 376;
const TABLET_MAX_WIDTH = 744;

const zoomPage = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  document.body.style.overflowX = vw <= SCROLL_X_WIDTH ? 'scroll' : 'hidden';
  if (vw < MOBILE_MIN_WIDTH) return;

  const ratio = vw < TABLET_MAX_WIDTH ? vw / MOBILE_MIN_WIDTH : 1.984;

  // @ts-ignore
  document.body.style.zoom = ratio.toString();
  document.body.style['-moz-transform'] = `scale(${ratio.toString()})`;
  document.body.style['-moz-transform-origin'] = '0 0';
};

window.addEventListener('resize', zoomPage);

window.onload = zoomPage;

const defaultQueryOptions = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    /** 外部API像mBMS通常很慢，不要retry */
    retry: 0,
    staleTime: 5 * 60 * 1000,
  },
};

const LineMobileApp = ({ element }) => {
  const [isErrorAlertModalShow, setIsErrorAlertModalShow] = useState(false);
  const [errorAlertTitle, setErrorAlertTitle] = useState('');
  const [errorAlertMessage, setErrorAlertMessage] = useState('');

  const handleQueryError = useCallback(async (/** @type {unknown} */ error) => {
    if (!APP_IS_DEBUG) return;

    if (error instanceof ApiError) {
      setErrorAlertTitle('API ERROR');
      setErrorAlertMessage(
        `API: ${error.baseUrl}\n` +
          `method: ${error.method.toUpperCase()}\n` +
          `url: ${error.url}\n` +
          `status: ${error.status}\n` +
          `code: ${error.code}\n` +
          `message: ${error.message}`
      );
    } else {
      setErrorAlertTitle('UNKNOWN ERROR');
      setErrorAlertMessage(JSON.stringify(error));
    }

    setIsErrorAlertModalShow(true);
  }, []);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: defaultQueryOptions,
        queryCache: new QueryCache({
          onError: handleQueryError,
        }),
      }),
    [handleQueryError]
  );

  return (
    <QueryClientProvider client={queryClient}>
      {/* <SharedLayout>{element}</SharedLayout> */}
      {SITE === 'LIFF' && <SharedLayoutLiff>{element}</SharedLayoutLiff>}
      {SITE === 'SIGNUP' && <SharedLayoutSignup>{element}</SharedLayoutSignup>}
      <ConfirmAlert
        titleText={errorAlertTitle}
        description={errorAlertMessage}
        buttonText="好"
        show={isErrorAlertModalShow}
        onConfirm={() => setIsErrorAlertModalShow(false)}
        isDebugError
      />
    </QueryClientProvider>
  );
};

LineMobileApp.propTypes = {
  element: PropTypes.element,
};

export const wrapPageElement = LineMobileApp;
