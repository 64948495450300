exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-binding-success-index-jsx": () => import("./../../../src/pages/binding-success/index.jsx" /* webpackChunkName: "component---src-pages-binding-success-index-jsx" */),
  "component---src-pages-contact-service-index-jsx": () => import("./../../../src/pages/contact-service/index.jsx" /* webpackChunkName: "component---src-pages-contact-service-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-message-index-jsx": () => import("./../../../src/pages/message/index.jsx" /* webpackChunkName: "component---src-pages-message-index-jsx" */),
  "component---src-pages-sign-up-index-jsx": () => import("./../../../src/pages/sign-up/index.jsx" /* webpackChunkName: "component---src-pages-sign-up-index-jsx" */),
  "component---src-pages-sign-up-validate-index-jsx": () => import("./../../../src/pages/sign-up/validate/index.jsx" /* webpackChunkName: "component---src-pages-sign-up-validate-index-jsx" */),
  "component---src-pages-signed-index-jsx": () => import("./../../../src/pages/signed/index.jsx" /* webpackChunkName: "component---src-pages-signed-index-jsx" */),
  "component---src-pages-signed-legacy-number-validate-index-jsx": () => import("./../../../src/pages/signed/legacy-number/validate/index.jsx" /* webpackChunkName: "component---src-pages-signed-legacy-number-validate-index-jsx" */),
  "component---src-pages-signed-new-number-apply-index-jsx": () => import("./../../../src/pages/signed/new-number/apply/index.jsx" /* webpackChunkName: "component---src-pages-signed-new-number-apply-index-jsx" */),
  "component---src-pages-signed-new-number-validate-index-jsx": () => import("./../../../src/pages/signed/new-number/validate/index.jsx" /* webpackChunkName: "component---src-pages-signed-new-number-validate-index-jsx" */),
  "component---src-pages-unbind-index-jsx": () => import("./../../../src/pages/unbind/index.jsx" /* webpackChunkName: "component---src-pages-unbind-index-jsx" */),
  "component---src-pages-unbind-mobile-index-jsx": () => import("./../../../src/pages/unbind-mobile/index.jsx" /* webpackChunkName: "component---src-pages-unbind-mobile-index-jsx" */),
  "component---src-pages-unqualified-index-jsx": () => import("./../../../src/pages/unqualified/index.jsx" /* webpackChunkName: "component---src-pages-unqualified-index-jsx" */)
}

