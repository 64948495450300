import { useMutation } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import _ from 'lodash';

import { useGetUserInfo } from '@/apis/hooks';
import { accessTokenAtom } from '@/hooks/useLiff';
import { encrypt } from '@/utils/crypt';

import { handleApiError, handleApiResponse, useSignUpApi } from '../axios';
import { API_URL } from '../axios/urls';

// LM-SIGNUP-API-05 解除舊用戶門號
export const usePostMobileUnLinkOldUser = () => {
  const accessToken = useAtomValue(accessTokenAtom);
  const { userInfo } = useGetUserInfo();
  const { apiPost } = useSignUpApi();

  /** @type {({mobile, otp}:{mobile:string, otp:string}) => Promise<{title: string, message: string}>} */
  const postMobileUnLinkOldUser = ({ mobile, otp }) =>
    apiPost(API_URL.MOBILE_UNLINK_OLD_USER, {
      data: {
        line_access_token: accessToken,
        token: _.get(userInfo, 'token'),
        mobile: encrypt(mobile),
        otp: otp,
      },
    })
      .then((response) => handleApiResponse(response)?.data)
      .catch(handleApiError);

  const { mutate, isSuccess, isLoading } = useMutation(
    [API_URL.MOBILE_UNLINK_OLD_USER],
    postMobileUnLinkOldUser
  );

  return {
    mutate,
    isSuccess,
    isLoading,
  };
};
