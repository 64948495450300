import { useCallback } from 'react';

import { atom, useAtom } from 'jotai';

// app is loading default
const isLoadingAtom = atom(true);

const useLoading = () => {
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);

  const showLoading = useCallback(() => setIsLoading(true), [setIsLoading]);
  const hideLoading = useCallback(() => setIsLoading(false), [setIsLoading]);

  return {
    isLoading,
    showLoading,
    hideLoading,
  };
};

export default useLoading;
