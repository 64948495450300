import { useMutation } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import _ from 'lodash';

import { handleApiError, handleApiResponse, useSignUpApi } from '@/apis/axios';
import { useGetUserInfo } from '@/apis/hooks';
import { accessTokenAtom } from '@/hooks/useLiff';

import { encrypt } from '../../utils/crypt';
import { API_URL } from '../axios/urls';

export class SignUpFormData {
  constructor(
    /** @type {{name: string, mobile: string, birthday: string, gender: string, privacyAgree: boolean, promotionAgree: boolean}} */
    { name, mobile, birthday, gender, privacyAgree, promotionAgree }
  ) {
    /** @type {string} */ this.name = name;
    /** @type {string} */ this.mobile = mobile;
    /** @type {string} */ this.birthday = birthday;
    /** @type {string} */ this.gender = gender;
    /** @type {boolean} */ this.privacyAgree = privacyAgree;
    /** @type {boolean} */ this.promotionAgree = promotionAgree;
  }
}

// LM-SIGNUP-API-03 綁定門號
export const usePostMobileLink = () => {
  const accessToken = useAtomValue(accessTokenAtom);
  const { userInfo } = useGetUserInfo();
  const { apiPost } = useSignUpApi();

  /** @type {({otp, formData, utm}: {otp:string, formData: SignUpFormData | { mobile: string }, utm: {}}) => Promise<{expire_date: string, otp: string}>} */
  const postMobileLink = ({ otp, formData, utm = null }) =>
    apiPost(API_URL.MOBILE_LINK, {
      // 後台mBMS問題會太慢，先讓前端等來解決
      timeout: 2 * 60 * 1000,
      data: {
        line_access_token: accessToken,
        token: _.get(userInfo, 'token'),
        otp,
        ...formData,
        /** 複寫mobile加密 */
        mobile: encrypt(_.get(formData, 'mobile')),
        /** 遊戲綁定來源:LM1 */
        action: _.get(utm, 'action'),
        gk: _.get(utm, 'gk'),
      },
    })
      .then((response) => handleApiResponse(response)?.data)
      .catch(handleApiError);

  const { mutate, isSuccess, isLoading } = useMutation(
    [API_URL.MOBILE_LINK],
    postMobileLink,
    {}
  );

  return {
    mutate,
    isSuccess,
    isLoading,
  };
};
