export class AxiosResponseClass {
  constructor(/** @type {import("axios").AxiosResponse} */ response) {
    this.data = response.data;
    this.status = response.status;
    this.statusText = response.statusText;
    this.headers = response.headers;
    this.config = response.config;
    this.request = response.request;
  }
}

export class ApiResponseData {
  constructor(
    /** @type {{ code:number, data:any, reason:string }} */ {
      code,
      data,
      reason,
    }
  ) {
    this.code = code;
    this.data = data;
    this.reason = reason;
  }
}

export class ApiError {
  constructor(
    /** @type {{ baseUrl: string, method: string, url: string, code: number, data: any, status: number, message: string }} */ {
      baseUrl,
      method,
      url,
      code,
      data,
      status,
      message,
    }
  ) {
    this.baseUrl = baseUrl;
    this.method = method;
    this.url = url;
    this.code = code;
    this.data = data;
    this.status = status;
    this.message = message;
  }
}
